import TopBar from "@urbanpiper-engineering/meraki-components/dist/Organisms/TopBar";
import React, { useEffect, useState } from "react";
import screenHelper from "../../external/helpers/screenHelper";
import { useRouter } from "next/router";
import configSelector from "../../external/configSelector";
import checkLightOrDark from "../../external/helpers/checkLightOrDark";
import colorConstants from "../../../../common/constants.json";

function TopBarWrapper() {
  const router = useRouter();
  const isCheckoutScreen = screenHelper.isCheckoutPage(router.pathname);
  const topbarConfig = configSelector.getGlobalComponent({ name: `topbar` });
  const [topbarColors, setTopbarColors] = useState({});
  const topbarBackgroundColor =
    topbarConfig?.customizations?.backgroundColor || "#f1f1f1";

  const lightOrDark = checkLightOrDark(topbarBackgroundColor);

  useEffect(() => {
    if (lightOrDark === "light") {
      setTopbarColors(colorConstants.colors.header.light);
    } else {
      setTopbarColors(colorConstants.colors.header.dark);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightOrDark]);

  if (isCheckoutScreen || !topbarConfig.customizations.enabled) {
    return null;
  }

  return (
    <TopBar
      contactUsTitle={topbarConfig?.customizations?.rightLink?.title}
      contactUs={topbarConfig?.customizations?.rightLink?.link}
      downloadAppTitle={topbarConfig?.customizations?.leftLink?.title}
      appleStoreLink={topbarConfig?.customizations?.leftLink?.iOSAppLink}
      playStoreLink={topbarConfig?.customizations?.leftLink?.androidAppLink}
      themeColors={{
        backgroundColor: topbarBackgroundColor,
        textColor: topbarColors.textColor,
        iconColor: topbarColors.iconColor,
      }}
    />
  );
}

export default TopBarWrapper;
